import { Injectable, NgZone } from '@angular/core';
import { AngularFireMessaging } from '@angular/fire/compat/messaging';
import { BehaviorSubject, Observable } from 'rxjs';
import { AngularFireAuth } from "@angular/fire/compat/auth";
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Router } from '@angular/router';
import { AngularFireDatabase } from '@angular/fire/compat/database';
import { environment } from './../../environments/environment';

@Injectable({
	providedIn: 'root'
})
export class MessagingService {
	currentMessage = new BehaviorSubject(null);
	
	constructor(private angularFireMessaging: AngularFireMessaging,public afAuth: AngularFireAuth,private firestore: AngularFirestore,public router: Router,private db: AngularFireDatabase) {
		this.afAuth.onIdTokenChanged(tokenChanged => {
			this.requestPermission();
		})
	}
	
	requestPermission(): Observable<any> {
		var token = this.angularFireMessaging.requestToken;
		return token;
	}
	
	register(email: string, password: string, token:any=null) {
		this.afAuth.signInWithEmailAndPassword(email, password).then((response:any) => {
			if(token){
				this.addUpdateTokenToDB(token);
			}
		}).catch((e) => {
			this.afAuth.createUserWithEmailAndPassword(email, password).then((response:any) => {
				if(token){
					this.addUpdateTokenToDB(token);
				}
			}).catch((e) => {
			});
		});
	}
	
	addUpdateTokenToDB(token:any){
		try {
			let userEmail:any = localStorage.getItem('userEmail');
			this.checkUserData(userEmail).then((isUser) => {
				if (isUser) {
					this.updateDataToDb(token, localStorage.getItem('OwnerId')).then(res => {});
				} else {
					this.addMsgCount(localStorage.getItem('OwnerId'));
					this.addUserDoctor(localStorage.getItem('OwnerId'), token, userEmail).then((done) => {}).catch((e) => {
					});
					this.addDataToDb(userEmail, token, localStorage.getItem('OwnerId')).then((done) => {}).catch((e) => {
					});
				}
			}).catch((newErr) => {})
		} catch (error) {}
	}
	
	async checkUserData(email: string) {
		const res = await this.firestore.collection('users').ref.where('email', '==', email).get();
		return res.docs.length === 0 ? false : true;
	}
	
	updateDataToDb(deviceId: any, uId: any) {
		let uId_string = uId.toString();
		var promise = new Promise<any>((resolve, reject) => {
			this.firestore
			.collection("users")
			.doc(uId_string)
			.set({ status: 'online', deviceId: deviceId }, { merge: true })
			.then(res => { resolve(true) }, err => reject(false));
		});
		return promise;
	}
	
	updateMsgStatus(uId: any) {
		var promise = new Promise<any>((resolve, reject) => {
			this.firestore
			.collection("doctors")
			.doc(uId)
			.set({ msgStatus: false }, { merge: true })
			.then(res => { resolve(true) }, err => reject(false));
		});
		return promise;
	};
	
	addMsgCount(uId: any) {
		let uId_string = uId.toString();
		let docData = {
			count: 0
		};
		var promise = new Promise<any>((resolve, reject) => {
			this.firestore
			.collection("msgcount")
			.doc(uId_string)
			.set(docData)
			.then(res => { resolve(true) }, err => reject(false));
		});
		return promise;
	};
	
	addMsgsToMessageVessel(data:any, uId:any) {
		
		var promise = new Promise<any>((resolve, reject) => {
			this.firestore
			.collection("messages")
			.doc("doctors")
			.collection(uId)
			.add(data)
			.then(res => { resolve(true) }, err => reject(false));
		});
		return promise;
	};
	
	addMsgsToMessageDoctor(data:any, uId:any) {
		var promise = new Promise<any>((resolve, reject) => {
			this.firestore
			.collection("messages")
			.doc(uId)
			.collection("doctors")
			.add(data)
			.then(res => { resolve(true) }, err => reject(false));
		});
		return promise;
	};
	
	updateChatTimeStamp(uId:any, text:any) {
		let updateFields = {
			'timestamp': new Date(),
			'addedBy': 'doctor',
			'latestMessage': text
		};
		var promise = new Promise<any>((resolve, reject) => {
			this.firestore
			.collection("doctors")
			.doc(uId)
			.set(updateFields, { merge: true })
			.then(res => { resolve(true) }, err => reject(false));
		});
		return promise;
	};
	
	async addUserDoctor(uId: any, deviceId: any, email: string) {
		let uId_string = uId.toString();
		let docData = {
			callStatus: "not_on_call",
			deviceId: deviceId,
			email: email,
			status: "online",
			userId: uId_string
		};
		
		var promise = new Promise<any>((resolve, reject) => {
			this.firestore
			.collection("doctorUser")
			.doc(uId_string)
			.set(docData)
			.then(res => { resolve(true) }, err => reject(false));
		});
		return promise;
	};
	
	addDataToDb(email: string, deviceId: string, uId: any) {
		let uId_string = uId.toString();
		let doc = {
			alternateId: email,
			deviceId: deviceId,
			email: email,
			state: 0,
			status: "online",
			type: "doctor",
			userId: uId_string
		};
		var promise = new Promise<any>((resolve, reject) => {
			this.firestore
			.collection("users")
			.doc(uId_string)
			.set(doc)
			.then(res => { resolve(true) }, err => reject(false));
		});
		return promise;
	};
	
	addVesselUserCall(data:any, uId:any) {
		
		var promise = new Promise<any>((resolve, reject) => {
			this.firestore
			.collection("call")
			.doc(uId)
			.set(data)
			.then(res => { resolve(true) }, err => reject(false));
		});
		return promise;
		
	};
	
	addDoctorUserCall(data:any, uId:any) {
		var promise = new Promise<any>((resolve, reject) => {
			this.firestore
			.collection("call")
			.doc(uId)
			.set(data)
			.then(res => { resolve(true) }, err => reject(false));
		});
		return promise;
	};
	
	removeDoctorUserCall() {
		var promise = new Promise<any>((resolve, reject) => {
			this.firestore
			.collection("call")
			.doc("doctors")
			.delete()
			.then(res => { resolve(true) }, err => reject(false));
		});
		return promise;
	};
	
	removeVesselUserCall(uId:any) {
		
		var promise = new Promise<any>((resolve, reject) => {
			this.firestore
			.collection("call")
			.doc(uId)
			.delete()
			.then(res => { resolve(true) }, err => reject(false));
		});
		return promise;
	};
	
	receiveMessage() {
		this.angularFireMessaging.onMessage((payload) => {
			this.currentMessage.next(payload);
		})
	}
	
	refreshToken() {
		/* this.angularFireMessaging.onTokenRefresh((token) => {
			this.requestPermission();
			this.sendTokenToServer(token);
		}) */
	}
	
	addVideoCallUser(data:any) {
		var promise = new Promise<any>((resolve, reject) => {
			this.firestore
			.collection("participants")
			.add(data)
			.then(res => { resolve(res) }, err => reject(err));
		});
		return promise;
	}
	
	addHostUser(data:any, uId:any) {
		
		var promise = new Promise<any>((resolve, reject) => {
			this.firestore
			.collection("host_status")
			.doc(uId)
			.set(data)
			.then(res => {resolve(true) }, err => {reject(false)});
		});
		return promise;
	}
	
	removeChannelEntryIfZeroHost(uId:any) {
		
		var promise = new Promise<any>((resolve, reject) => {
			this.firestore
			.collection("host_status")
			.doc(uId)
			.delete()
			.then(res => { resolve(true) }, err => reject(false));
		});
		return promise;
	}
	
	sendMessageInVideoCall(data:any) {
		var promise = new Promise<any>((resolve, reject) => {
			this.firestore
			.collection("chat")
			.add(data)
			.then(res => { resolve(res) }, err => reject(err));
		});
		return promise;
	}
}